import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/feedback_staffs', data);
  },
  get(id) {
    return api.fetch('/feedback_staffs/' + id);
  },
  list(params) {
    return api.fetch('/feedback_staffs', { params });
  },
  update(data) {
    return api.patch('/feedback_staffs', data);
  },
  delete(id) {
    return api.remove('/feedback_staffs', id);
  },
};
